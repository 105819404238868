import React from 'react';
import ReactDOM from 'react-dom';

import { datadogRum } from '@datadog/browser-rum';

import reportWebVitals from './reportWebVitals';

import './index.css';

require('dotenv').config();

function importBuildTarget() {
  // DefinePlugin in webpack.config.js will substitute
  // process.env.REACT_APP_BUILD_TARGET with it's value at build time.
  // https://webpack.js.org/plugins/define-plugin/

  // TerserPlugin in webpack.config.js will eliminate dead code
  // ...if we make it easy enough (no maps or switches, etc).
  // https://webpack.js.org/plugins/terser-webpack-plugin/

  if (process.env.REACT_APP_BUILD_TARGET === "embed") {
    return import("./Embed");
  } else {
    return import('./App');
  }
}

if (process.env.REACT_APP_DATADOG_ENV) {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APP_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'rainbook-web-app',
    env: process.env.REACT_APP_DATADOG_ENV,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100, //20
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input'
  });
  datadogRum.startSessionReplayRecording();
}

importBuildTarget().then(({ default: Environment }) =>
  ReactDOM.render(
    <React.StrictMode>
      <Environment />
    </React.StrictMode>,
    document.getElementById('root')
  )
);

reportWebVitals();
